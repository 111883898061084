import React from 'react'
import Layout from '../components/Layout'
import { Col, Container, Row } from 'react-bootstrap'
import Seo from '../components/Seo'
import SeoheadData from '../siteScriptData'

function thankyou() {
  return (
    <div className='thankyou'>
      <Layout footernone={false}>
        <Seo SeoData={SeoheadData.thankyouPageSeoData}></Seo>
        <Container>
          <div className='thankyou-page'>
            <Row className='align-items-center'>
              <Col lg={6}>
                <div className="thankyou-img text-center">
                  <img src="../assets/img/thankyou/thankyou.svg" alt="thankyou" className='img-fluid' />
                </div>
              </Col>
              <Col lg={6}>
                <div className="thankyou-contain">
                  <h2>Thank you! Your <br />
                    requirements have been submitted
                    successfully.</h2>
                  <h3>We will contact you soon.</h3>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </div>

  )
}

export default thankyou